import { DateRangeFilter } from "@/global_filter"
import { DistributorFilter } from "@/global_filter"
import { Searchbar } from "@/app/searchbar.tsx"
import { BreadcrumbNav, BreadcrumbNavProps } from "@/app/breadcrumb.tsx"

export function GlobalFilterBar() {
  return <div class="space-x-2 flex">
    <DistributorFilter/>
    <DateRangeFilter />
  </div>
}

export function Navbar() {
  return <div class="flex justify-between pb-12 space-x-2">
    <Searchbar />
    <GlobalFilterBar />
  </div>
}

export function DetailNavbar({items}: BreadcrumbNavProps) {
  return <div class="flex justify-between">
    <BreadcrumbNav items={items}/>
    <Searchbar />
  </div>
}
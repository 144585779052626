import keheLogo from "/img/kehe-logo.png?url"
import { useState } from "preact/compat"
import { Integration } from "@/api/deduction.tsx"
import { Ok, Result } from "src/utils/type.tsx"
import { useLocation } from "wouter-preact"
import { api_fetch, HttpError } from "../api/client.tsx"
import { Form } from "@/components/form/Form.tsx"
import { useFormState } from "@/components/form/form_state.tsx"
import { Input, PasswordInput } from "@/components/form/Input.tsx"
import { LoginScreen } from "../components/LoginScreen.tsx"
import { Button } from "@/components/ui/button.tsx"

async function create_kehe_integration(
  username: string,
  password: string,
): Promise<Result<Integration, HttpError>> {
  let options = {
    body: {
      service: "kehe",
      credential: {
        username: username,
        password: password,
      },
    },
  }
  const res = await api_fetch<Integration>("/integration", options)
  if (!res.ok) {
    console.error(res.error)
    return res
  }
  return Ok(res.value.data)
}

export function KeheIntegration() {
  const [, setLocation] = useLocation()
  const [loading, setLoading] = useState(false)

  const form = useFormState({
    username: "",
    password: "",
  })

  async function onSubmit() {
    setLoading(true)
    form.set_form_errors([])
    const res = await create_kehe_integration(form.username.value, form.password.value)
    setLoading(false)
    if (!res.ok) {
      form.set_form_errors([
        "KeHE login failed. Please try again.",
        // res.error.message.replace("Anyhow error: ", ""),
      ])
      return
    }
    setLocation(`/setup/freshdesk`)
  }

  return <LoginScreen title="">
    <div>
      <Form class="space-y-6" onSubmit={onSubmit} alerts={form.form_alerts}>
        <img src={keheLogo} />
        <p className="mt-6">
          Enter your KeHE Connect account information so we can sync your KeHE data for you.
        </p>
        <Input
          label="Username"
          type="text"
          name="username"
          required={true}
          bind={form.username}
        />
        <PasswordInput
          label="Password"
          required={false}
          bind={form.password}
        />
        <Button
          type="submit"
          className="w-full btn text-white bg-kehe-500 rounded-md hover:bg-kehe-600 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-plue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
          disabled={loading}
        >
          Connect Account
        </Button>
        {loading && <div class="w-full text-center">Trying to login...this might take a few seconds</div>}
      </Form>
    </div>
  </LoginScreen>
}

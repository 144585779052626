import { api_fetch, DeductionResponse } from "@/api"
import { Navbar } from "@/app/navbar"
import { Button } from "@/components/ui/button"
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible"
import { INVOICE_COLUMNS } from "@/dashboard/sales/sales_columns"
import { StatusFlag } from "@/deductions/status_state"
import { SummaryCards } from "@/deductions/summary_cards"
import { BASE_COLUMNS } from "@/deductions/table/columns"
import { DeductionTable } from "@/deductions/table/data_table"
import { DataTableState } from "@/deductions/table_state.tsx"
import {
  map,
  toISODateString,
  useAsyncEffect,
  useSearchParams,
} from "@/utils"
import { Filter } from "lucide-react"
import { useEffect, useMemo, useState } from "preact/compat"

export function cleanDeductionList(data: DeductionResponse[]): DeductionResponse[] {
  return data.map(d => {
    d.category = d.category ?? "Misc"
    let kv: [string, any][] = Object.entries(d).map(([k, v]) => [k, v ?? ""])
    kv.push(["validated", d.status_value === "validated"])
    return Object.fromEntries(kv) as DeductionResponse
  })
}

export function Deductions() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<DeductionResponse[]>([])
  const statusFlag = StatusFlag.use(sf => sf!)
  const tableState = DataTableState.use(ts => ts!)
  const [isOpen, setIsOpen] = useState(false)

  // Get the initial search value from URL query params and decode it
  const { search: encodedSearch } = useSearchParams<{ search?: string }>()
  const urlSearch = encodedSearch ? decodeURIComponent(encodedSearch) : undefined

  // Use useEffect to set the initial search from URL if it exists
  useEffect(() => {
    if (urlSearch && urlSearch !== tableState.search) {
      DataTableState.set(s => ({ ...s, search: urlSearch }))
    }
  }, [urlSearch])

  const { startDate, endDate, distributor, transactionTypes } = tableState

  useAsyncEffect(async () => {
    const res = await api_fetch<DeductionResponse[]>("/deduction/list", {
      method: "POST",
      body: {
        start_date: map(startDate, toISODateString),
        end_date: map(endDate, toISODateString),
        distributor,
        transaction_types: transactionTypes,
      },
    })
    setLoading(false)
    if (!res.ok) {
      throw new Error("Failed to fetch data")
    }
    let data = cleanDeductionList(res.value.data)
    setData(data)
  }, [startDate, endDate, statusFlag, distributor, transactionTypes])

  // Update the filterableColumns to only include visible columns
  const filterableColumns = BASE_COLUMNS.filter(column => {
    // Exclude action/special columns
    const excludedColumns = ["select", "actions", "files", "task"]
    // @ts-ignore
    const columnKey = column.id || (column.accessorKey as string)
    if (excludedColumns.includes(columnKey)) {
      return false
    } else {
      return tableState.columnVisibility[columnKey] ?? true
    }
    // return (!excludedColumns.includes(columnKey) && (!tableState.columnVisibility || tableState.columnVisibility[columnKey]))
  }).map(column => {
    // Extract the title from the header component
    let title = ""
    if (typeof column.header === "function") {
      const headerProps = { column: { id: column.id } }
      const headerResult = column.header(headerProps as any)
      // Access the title prop from the ColumnHeader component
      // @ts-ignore
      title = headerResult?.props?.title || column.id || (column.accessorKey as string)
    } else {
      // @ts-ignore
      title = column.id || (column.accessorKey as string)
    }
    return { ...column, title }
  })

  // Add effect to handle opening collapsible when filters are present
  useEffect(() => {
    const hasFilters = tableState.columnFilters && tableState.columnFilters.length > 0
    if (hasFilters && !isOpen) {
      setIsOpen(true)
    } else if (!hasFilters && isOpen) {
      setIsOpen(false)
    }
  }, [tableState.columnFilters])

  // Determine which columns to use
  const columns = useMemo(() => {
    if (tableState.transactionTypes?.length === 1 && tableState.transactionTypes[0] === "invoice") {
      return INVOICE_COLUMNS
    }
    return BASE_COLUMNS
  }, [tableState.transactionTypes])

  return (
    <div>
      <Navbar/>
      <SummaryCards />
      <div className="flex flex-col gap-4 pt-4">
        <div className="flex justify-between items-center w-full">
          <Collapsible open={isOpen} onOpenChange={setIsOpen} className="w-full">
            <div className="flex justify-between items-center">
              <CollapsibleTrigger asChild>
                {/* @ts-ignore */}
                <Button variant="outline" size="sm">
                  <Filter className="h-4 w-4 mr-2" />
                  Filters
                </Button>
              </CollapsibleTrigger>
              <div className="flex gap-4">
              </div>
            </div>
            <CollapsibleContent className="mt-4">
              <div className="grid grid-cols-5 gap-4">
                {filterableColumns.map(column => {
                  // @ts-ignore
                  const columnKey = column.id || (column.accessorKey as string)
                  const columnFilters = Array.isArray(tableState.columnFilters) ? tableState.columnFilters : []
                  return (
                    <div key={columnKey} className="space-y-2">
                      <label className="text-sm text-plue-900">{column.title}</label>
                      <input
                        className="w-full rounded-md px-3 py-2"
                        placeholder={
                          // @ts-ignore
                          column.id?.includes("date") || column.accessorKey?.includes("date")
                            ? "MM/DD or MM/DD/YYYY"
                            : ""
                        }
                        value={columnFilters.find(f => f.id === columnKey)?.value as string || ""}
                        onChange={(e: Event) => {
                          const target = e.target as HTMLInputElement
                          const value = target.value
                          DataTableState.set({
                            ...tableState,
                            columnFilters: value
                              ? [...columnFilters.filter(f => f.id !== columnKey), { id: columnKey, value }]
                              : columnFilters.filter(f => f.id !== columnKey),
                          })
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            </CollapsibleContent>
          </Collapsible>
        </div>
      </div>
      <div className="mx-auto">
        <DeductionTable loading={loading} columns={columns} data={data} />
      </div>
    </div>
  )
}

import { DeductionResponse } from "@/api/deduction.tsx"
import { captureException } from "@sentry/react"
import { capitalCase } from "change-case"
import { ClassValue, clsx } from "clsx"
import type { JSX } from "preact"
import { useEffect } from "preact/compat"
import { twMerge } from "tailwind-merge"
import { useSearch } from "wouter-preact/use-location"
export * from "./type"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function toISODateString(date: Date) {
  return date.toISOString().substring(0, 10)
}

export function map_or<T, U>(value: T | undefined, default_: U, f: (value: T) => U): U {
  return value === undefined ? default_ : f(value)
}

export function map<T, U>(value: T | undefined | null, f: (value: T) => U): U | undefined {
  return value == undefined ? undefined : f(value)
}

export function canDispute(deduction: DeductionResponse) {
  return deduction.status_value !== "disputed"
}

export function classes(...args: (string | JSX.SignalLike<string | undefined> | undefined | boolean)[]) {
  return args.filter(Boolean).join(" ")
}

export function useAsyncEffect(effect: () => Promise<any>, deps: any[] = []) {
  return useEffect(() => safeExec(effect), deps)
}

export function safeExec(action: () => Promise<any>) {
  action().catch(e => {
    captureException(e)
    console.log(e)
  })
}

export function useSearchParams<T extends object>(): T {
  let search_string: string = useSearch()
  let search_params = new URLSearchParams(search_string)
  return Object.fromEntries(search_params.entries()) as T
}

export function formatDistributor(name: string): string {
  if (!name) return ""
  const lowerName = name.toLowerCase()
  if (lowerName.includes("kehe")) {
    return "KeHE"
  } else if (lowerName.includes("unfi")) {
    return "UNFI"
  } else if (lowerName.includes("cands")) {
    return "C&S"
  } else if (lowerName.includes("heb")) {
    return "HEB"
  } else if (lowerName.includes("albertsons")) {
    return "Albertson's"
  } else if (lowerName.includes("demoulas")) {
    return "Demoula's"
  } else if (lowerName.includes("cvs")) {
    return "CVS"
  } else if (lowerName.includes("geyser")) {
    return "Big Geyser"
  } else {
    return capitalCase(name)
  }
}

export function formatOriginalDistributor(original_source: string): string {
  if (!original_source) return ""
  
  // Filter out any leading numbers and join the remaining words for the original source that comes from super's accounting system
  const words = original_source.split(' ').filter(word => isNaN(Number(word)))
  return capitalCase(words.join(' '))
}


const FORMATTER = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})
const DATE_FORMATTER = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
})
export const FULL_DATE_FORMATTER = new Intl.DateTimeFormat("en-US", {
  month: "2-digit",
  day: "2-digit",
  year: "numeric",
})

// whole dollar amounts, no cents
export function currencyFormatter(number: number) {
  return FORMATTER.format(Math.round(number)).toString().slice(0, -3)
}

export function exactCurrencyFormatter(number: number) {
  return FORMATTER.format(number)
}

export const dateFormatter = function(
  date: string | undefined,
  formatter = DATE_FORMATTER,
): string {
  if (!date) {
    return ""
  }
  try {
    const parsedDate = new Date(date)
    const timezoneOffset = parsedDate.getTimezoneOffset() * 60000 // offset in milliseconds
    const adjustedDate = new Date(parsedDate.getTime() + timezoneOffset)
    return formatter.format(adjustedDate)
  } catch (e) {
    console.warn(`Could not format data as date: ${date}`)
    return date
  }
}
export function index_map<T>(values: string[]): Record<string, number> {
  return Object.fromEntries(values.map((header, index) => [header, index]))
}

export function stopPropagation(event: Event) {
  event.preventDefault()
  event.stopImmediatePropagation()
}

if (import.meta.vitest) {
  describe('formatDistributor', () => {
    it('should return "KeHE" for names including "kehe"', () => {
      expect(formatDistributor('kehe distributors')).toBe('KeHE')
      expect(formatDistributor('KEHE')).toBe('KeHE')
    })

    it('should return "UNFI" for names including "unfi"', () => {
      expect(formatDistributor('unfi wholesale')).toBe('UNFI')
      expect(formatDistributor('UNFI')).toBe('UNFI')
    })

    it('should return capitalized case for other names', () => {
      expect(formatDistributor('other distributor')).toBe('Other Distributor')
    })

    it('should return an empty string for empty input', () => {
      expect(formatDistributor('')).toBe('')
    })
  })

  describe('classes', () => {
    it('should join non-empty string arguments', () => {
      expect(classes('class1', 'class2', 'class3')).toBe('class1 class2 class3')
    })

    it('should filter out falsy values', () => {
      // @ts-ignore
      expect(classes('class1', undefined, 'class2', null, 'class3', false, '')).toBe('class1 class2 class3')
    })

    it('should return an empty string when all arguments are falsy', () => {
      // @ts-ignore
      expect(classes(undefined, null, false, '')).toBe('')
    })
  })

  describe('formatOriginalDistributor', () => {
    it('should remove leading numbers and spaces', () => {
      expect(formatOriginalDistributor('650 McLane Co.')).toBe('Mc Lane Co')
      expect(formatOriginalDistributor('123 ABC Company')).toBe('Abc Company')
    })

    it('should handle strings without leading numbers', () => {
      expect(formatOriginalDistributor('McLane Co.')).toBe('Mc Lane Co')
      expect(formatOriginalDistributor('Simple Company')).toBe('Simple Company')
    })

    it('should return an empty string for empty input', () => {
      expect(formatOriginalDistributor('')).toBe('')
    })

    it('should handle multiple leading numbers', () => {
      expect(formatOriginalDistributor('123 456 Company Name')).toBe('Company Name')
    })
  })
}

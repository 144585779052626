import { Alert } from "./type"
import { classes } from "@/utils"

interface AlertsProps {
  alerts: Alert[] | null
  class?: string
}

export function Alerts({ alerts, class: klas }: AlertsProps) {
  return <div class={classes(klas)}>
    {alerts && alerts.map((alert, i) => <div class={classes(alert.type === 'success' ? 'text-green-600' : 'text-red-600')} key={i}>{alert.message}</div>)}
  </div>
}

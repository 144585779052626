import { useState } from "preact/hooks"
import { DateRange } from "react-day-picker"
import { endOfMonth, format, startOfMonth, startOfYear, subMonths, subYears } from "date-fns"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Button } from "@/components/ui/button"
import { classes, Option } from "@/utils"
import { Calendar as CalendarIcon } from "lucide-react"
import { Calendar } from "@/components/ui/calendar"
import { ComponentChildren } from "preact"
import { DataTableState } from "@/deductions/table_state"

interface DateRangeFilterProps {
  className?: string
}

type Period = "currentMonth" | "previousMonth" | "past6Months" | "pastYear" | "ytd" | "allTime"

interface QuickButtonProps {
  period: Period
  children: ComponentChildren
  handler: (period: Period) => void
}

function QuickButton({ period, children, handler }: QuickButtonProps) {
  return (
    <Button
      variant="outline"
      // @ts-ignore
      size="sm"
      onClick={() => handler(period)}
    >
      {children}
    </Button>
  )
}

interface StartEnd {
    startDate: Option<Date>,
    endDate: Option<Date>,
}

function getStartEnd(period: Period): StartEnd {
  const now = new Date()
  let start: Option<Date>
  let end: Option<Date>

  switch (period) {
    case "currentMonth":
      start = startOfMonth(now)
      end = endOfMonth(now)
      break
    case "previousMonth":
      start = startOfMonth(subMonths(now, 1))
      end = endOfMonth(subMonths(now, 1))
      break
    case "past6Months":
      start = subMonths(now, 6)
      end = now
      break
    case "pastYear":
      start = subYears(now, 1)
      end = now
      break
    case "ytd":
      start = startOfYear(now)
      end = now
      break
    case "allTime":
      start = null
      end = null
      break
  }
  return {
    startDate: start,
    endDate: end,
  }
}

export function DateRangeFilter({ className }: DateRangeFilterProps) {
  const [open, setOpen] = useState(false)
  const [start, end] = DataTableState.use(s => [s.startDate, s.endDate])

  function handleSelect(range: DateRange | undefined) {
    if (!range?.from) return
    let startDate = range.from ?? null
    let endDate = range.to ?? null
    DataTableState.set(s => ({ ...s, startDate, endDate }))
  }

  function handleQuickButton(period: Period) {
    let { startDate, endDate } = getStartEnd(period)
    DataTableState.set(s => ({ ...s, startDate, endDate }))
    setOpen(false)
  }

  const displayText = start && end
    ? `${format(start, "MM/dd/yyyy")} - ${format(end, "MM/dd/yyyy")}`
    : "Select date range"

  return (
    <div className={className}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={classes(
              "justify-start text-left font-normal",
              // @ts-ignore
              !start && "text-muted-foreground",
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {displayText}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0 flex" align="start">
          <div className="flex flex-wrap gap-2 p-3 border-t border-border flex flex-col">
            <QuickButton period="currentMonth" handler={handleQuickButton}>Current Month</QuickButton>
            <QuickButton period="previousMonth" handler={handleQuickButton}>Previous Month</QuickButton>
            <QuickButton period="past6Months" handler={handleQuickButton}>Past 6 Months</QuickButton>
            <QuickButton period="ytd" handler={handleQuickButton}>YTD</QuickButton>
            <QuickButton period="pastYear" handler={handleQuickButton}>Past Year</QuickButton>
            <QuickButton period="allTime" handler={handleQuickButton}>All Time</QuickButton>
          </div>
          <Calendar
            autoFocus
            mode="range"
            defaultMonth={start ?? undefined}
            selected={{
              from: start ?? undefined,
              to: end ?? undefined,
            }}
            onSelect={handleSelect}
            numberOfMonths={start ? 2 : 1}
            min={2} // Minimum range of 1 day
            max={500} // Maximum range of 500 days
            required
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}

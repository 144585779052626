import { TrashIcon } from "@heroicons/react/20/solid"
import { capitalCase } from "change-case"
import { ComponentChildren } from "preact"
import { useState } from "react"
import { Integration } from "@/api/deduction.tsx"
import { UserState } from "src/auth/user.tsx"
import { api_fetch } from "../api/client.tsx"
import { classes, safeExec, useAsyncEffect } from "@/utils"

/** Team is 1-1 with a warehouse, so when you select a team, that will set the global corresponding warehouse.
 *
 * @constructor
 */
export function Settings() {
  const user = UserState.use((u) => u!)
  return (
    <div class="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8 mb-8 mt-2">
      <ConnectionSettings />
      <ProfileSettings />
    </div>
  )
}

export function ProfileSettings() {
  const email = UserState.use((u) => u!.email)
  return (
    <Section title="Profile">
      <Row title="Email Address">{email}</Row>
      <Row title="Subscription">
        <a href={import.meta.env.VITE_STRIPE_CUSTOMER_PORTAL} class="text-plue-600">
          Manage billing
        </a>
      </Row>
    </Section>
  )
}

function ConnectionSettings() {
  const [integrations, setIntegrations] = useState<Integration[]>([])

  useAsyncEffect(async () => {
    let qs = new URLSearchParams()

    const res = await api_fetch<Integration[]>(`/integration?${qs}`)
    if (!res.ok) {
      return
    }
    setIntegrations(res.value.data)
  }, [])

  function deleteConnection(id: string) {
    let ans = confirm("Are you sure you want to delete this connection?")
    if (!ans) {
      return
    }
    safeExec(async () => {
      let res = await api_fetch(`/integration/${id}`, {
        method: "DELETE",
      })
      setIntegrations(integrations.filter((i) => i.id !== id))
    })
  }

  return (
    <Section title="Connections" class="first:pt-6">
      {integrations.map((integration) => {
        let name = capitalCase(integration.name)
        return (
          <div class="flex justify-between gap-x-6 pt-3">
            <span>{name}</span>
            <span class="flex items-center">
              {integration.username}
              <button
                onClick={() => deleteConnection(integration.id)}
                alt="Delete Connection"
                type="button"
              >
                <TrashIcon class="ml-1 h-5 w-5 text-red-400" />
              </button>
            </span>
          </div>
        )
      })}
    </Section>
  )
}

interface SectionProps {
  children: ComponentChildren
  title: string
  class?: string
}

export function Section({ children, title, ...props }: SectionProps) {
  return (
    <div class={classes("pt-12", props.class)}>
      <h2 className="text-lg font-semibold leading-7 text-gray-900">{title}</h2>
      <dl className="space-y-3 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
        {children}
      </dl>
    </div>
  )
}

interface RowProps {
  children: ComponentChildren
  title: string
}

export function Row({ children, title, ...props }: RowProps) {
  return (
    <div className="pt-3 sm:flex">
      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{title}</dt>
      <dd className="flex justify-end gap-x-6 sm:flex-auto">{children}</dd>
    </div>
  )
}

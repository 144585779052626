import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.tsx"
import { Link } from "wouter-preact"

interface Breadcrumb {
  href: string
  label: string
}

export interface BreadcrumbNavProps {
  items: Breadcrumb[]
}

export function BreadcrumbNav({items} : BreadcrumbNavProps) {
  // let breadcrumbs = BreadcrumbState.get()
  return (
    <div className="">
      <header className="">
        <Breadcrumb className="">
          <BreadcrumbList className="flex-nowrap">
            {items.map((item, index) => (
              <>
                <BreadcrumbItem>
                  <BreadcrumbLink asChild>
                    <Link href={item.href}>{item.label}</Link>
                  </BreadcrumbLink>
                </BreadcrumbItem>
                {index < items.length - 1 && <BreadcrumbSeparator />}
              </>
            ))}
          </BreadcrumbList>
        </Breadcrumb>
      </header>
    </div>
  )
}
'use client'

import { useState } from 'react'
import { Deduction } from "@/api/deduction.tsx"
import { useAsyncEffect } from "@/utils"
import { api_fetch } from "@/api/client.tsx"
import { useLocation } from "wouter-preact"

export function Searchbar() {
  const [results, setResults] = useState<Deduction[]>([])
  const [query, setQuery] = useState('')
  const [, setLocation] = useLocation()

  useAsyncEffect(async () => {
    if (query === '') {
      setResults([])
      return
    }
    let res = await api_fetch<Deduction[]>('/deduction', { params: { q: query } })
    if (!res.ok) {
      throw new Error('Failed to fetch data')
    }
    setResults(res.value.data)
  }, [query])

  function onChange(id: string) {
    setLocation(`/deduction/${id}`)
  }

  return (
    <div className="flex md:w-64">
      <div className="relative w-full max-w-2xl">
        <input
          type="text"
          value={query}
          onChange={(e: any) => setQuery(e.target.value)}
          placeholder="Search all invoice numbers..."
          className="w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          aria-label="Search"
        />
        {results.length > 0 && (
          <ul
            className="absolute w-full bg-white mt-1 border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto z-10">
            {results.map((ded) => (
              <li
                key={ded.id}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm text-gray-700"
                onClick={() => onChange(ded.id)}
              >
                {ded.invoice_number}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

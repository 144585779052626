import { useLocation } from "wouter-preact"
import { api_fetch } from "../api/client.tsx"
import { User } from "../api/user.tsx"
import { DEFAULT_LOGGED_IN_PATH } from "../app/route.tsx"
import { useFormState } from "@/components/form/form_state.tsx"
import { LoginScreen } from "../components/LoginScreen.tsx"
import { UserState } from "./user.tsx"

import { Button } from "@/components/ui/button.tsx"
import { Logo } from "../app/logo.tsx"
import { Form } from "@/components/form/Form.tsx"
import { EmailInput, PasswordInput } from "@/components/form/Input.tsx"
import { useSearchParams } from "@/utils"

export function Login() {
  const [, setLocation] = useLocation()
  const form = useFormState({
    email: "",
    password: "",
  })

  const params = useSearchParams<{ next?: string; token?: string }>()
  const next = params.next ?? DEFAULT_LOGGED_IN_PATH

  async function onSubmit() {
    if (form.password.value) {
      const res = await api_fetch<User>("/auth/login_password", {
        body: form.form_data,
      })
      if (!res.ok) {
        form.set_form_errors([res.error.message])
        return
      }
      UserState.set(res.value.data)
      setLocation(next)
    } else {
      const res = await api_fetch("/auth/login_magic_link", {
        body: form.form_data,
      })
      if (!res.ok) {
        form.set_form_errors([res.error.message])
        return
      }
      form.set_form_success("Check your email for a login link!")
    }
  }

  let button_text = form.password.value ? "Log In" : "Email Me a Login Link"

  return (
    <LoginScreen title="Log in to your account" logo={<Logo />}>
      <Form class="space-y-6" onSubmit={onSubmit} alerts={form.form_alerts}>
        <EmailInput label="Email address" required={true} bind={form.email} />
        <PasswordInput label="Password" required={false} bind={form.password} />
        <Button type="submit" className="w-full" variant="tertiary">
          {button_text}
        </Button>
      </Form>
    </LoginScreen>
  )
}

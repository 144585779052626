import { useEffect } from "preact/compat"
import { useLocation } from "wouter-preact"
import { UserState } from "./user"
import { safeExec } from "@/utils"

export function Logout(): JSX.Element {
  const [, setLocation] = useLocation()

  useEffect(() =>
    safeExec(async () => {
      await fetch("/api/auth/signout", { method: "GET" })
      // todo: this isn't actually working?
      UserState.set(null)
      setLocation("/")
    }), [])

  return <div></div>
}

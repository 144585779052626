import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";


import { cn } from "@/utils";

export type BadgeVariantOptionType = "new" | "review" | "validated" | "won" | "pending" | "disputed" | "backup_requested" | "backup_received" | "processing" | "disputable" | "default" | "secondary" | "destructive" | "outline" | undefined;
const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-sm transition-colors focus:outline-none focus:ring-1 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        new: "border-transparent bg-red-200 text-red-800 hover:bg-red-300",
        validated: "border-transparent bg-green-200 text-green-800 hover:bg-green-300",
        disputable: "border-transparent bg-yellow-200 text-yellow-800 hover:bg-yellow-300",
        review: "border-transparent bg-yellow-300 text-yellow-900 hover:bg-yellow-400",
        backup_requested: "border-transparent bg-slate-200 text-slate-800 hover:bg-slate-300",
        backup_received: "border-transparent bg-orange-200 text-orange-800 hover:bg-orange-300",
        disputed: "border-transparent bg-slate-300 text-slate-900 hover:bg-slate-400",
        processing: "border-transparent bg-slate-400 text-slate-900 hover:bg-slate-500",
        won: "border-transparent bg-green-300 text-green-900 hover:bg-green-400",
        lost: "border-transparent bg-red-300 text-red-900 hover:bg-red-400",
        pending: "border-transparent bg-gray-400 text-gray-600 hover:bg-gray-500",
        misc: "border-transparent bg-slate-500 text-slate-900 hover:bg-slate-600",
        default: "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary: "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        outline: "text-foreground",
        warning: "border-transparent bg-yellow-300 text-yellow-900 hover:bg-yellow-400",
        success: "border-transparent bg-green-200 text-green-800 hover:bg-green-300",
        destructive: "border-transparent bg-red-200 text-red-800 hover:bg-red-300",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants };

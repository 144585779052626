import Confetti from "react-confetti"
import useWindowSize from "react-use/lib/useWindowSize"
import { Link } from "wouter-preact"
import { Logo } from "../app/logo.tsx"
import { LoginScreen } from "../components/LoginScreen.tsx"

export function SetupComplete() {
  const { width, height } = useWindowSize()

  return (
    <LoginScreen title="" logo={<Logo />}>
      <Confetti width={width} height={height} numberOfPieces={600} recycle={false}></Confetti>
      <p>
        The system is now pulling in your data and categorizing your deductions. Click finish to see your dashboard which should be fully populated in a few minutes.
      </p>
      <div>
        <div class="w-full flex justify-center">
          <Link
            class="w-full mt-6 mb-4 btn btn-tertiary text-center rounded-md  
                            focus:outline-none focus:ring-1 focus:ring-offset-2"
            href="/deductions"
          >
            Finish
          </Link>
        </div>
      </div>
    </LoginScreen>
  )
}

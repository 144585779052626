import { Err, map, Ok, Option, Result, toISODateString } from "@/utils"
import { api_fetch, HttpError } from "@/api/client"

export type Cell = string | number | boolean

export interface QueryData {
  headers: string[]
  rows: Cell[][]
}

export interface Data<T extends any[]> {
  headers: string[]
  rows: T[]
}

interface FetchQueryData {
  start: Option<Date>
  end: Option<Date>
  distributor: Option<string>
}

export async function fetch_query_data(name: string, req: FetchQueryData): Promise<Result<QueryData, HttpError>> {
  let params = {
    start_date: map(req.start, toISODateString),
    end_date: map(req.end, toISODateString),
    distributor: req.distributor,
    query_name: name,
  }
  let res = await api_fetch<QueryData>("/deductions/query", { params })
  if (!res.ok) {
    return res
  } else {
    return Ok(res.value.data)
  }
}
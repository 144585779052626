import { DataTableState } from "@/deductions/table_state"
import { AccountingState } from "@/accounting/index"
import { StatusFlag } from "@/deductions/status_state"
import { currencyFormatter, index_map, useAsyncEffect } from "@/utils"
import { fetch_query_data } from "@/api"
import { LoadingSpinner } from "@/app/loading"
import { BarList } from "@tremor/react"
import { useState } from "preact/compat"
import { BreakdownData, FALLBACK_COLORS } from "@/components/breakdown"

const EXPENSE_ACCOUNT_COLORS = {
  "Uncoded": "red",
  "Sales Discounts": "blue",
  "Advertising": "green",
  "Freight": "orange",
  "Spoilage": "red",
  "Slotting": "purple",
  "Other": "gray",
  // Add more expense account colors as needed
}
export function AccountingBreakdown() {
  const [data, setData] = useState<BreakdownData[]>([])
  const [loading, setLoading] = useState(true)
  const [start, end, distributor] = DataTableState.use(u => [u.startDate, u.endDate, u.distributor])
  const accountingState = AccountingState.use()
  const statusFlag = StatusFlag.use(sf => sf!)

  useAsyncEffect(async () => {
    if (accountingState.codedFilter === "uncoded") {
      setLoading(false)
      setData([])
      return
    }
    let res = await fetch_query_data("expense_account_buckets", { start, end, distributor })
    setLoading(false)
    if (!res.ok) {
      setData([])
      throw new Error("Failed to fetch data")
    }

    let { rows, headers } = res.value
    let lookup = index_map(headers)

    rows = rows.filter(row => row[lookup.name] !== "Total")
    rows.sort((a, b) => (b[lookup.value] as number) - (a[lookup.value] as number))

    let data = rows.map((row, index) => {
        const name = row[lookup.name] as keyof typeof EXPENSE_ACCOUNT_COLORS
        return {
          name,
          value: Math.abs(row[lookup.value] as number) as number,
          color: EXPENSE_ACCOUNT_COLORS[name] || FALLBACK_COLORS[index % FALLBACK_COLORS.length],
        }
      });
    setData(data)
  }, [start, end, distributor, statusFlag])

  if (loading || !data) {
    return <LoadingSpinner color="blue" />
  }

  return (
    <div className="w-full">
      <BarList
        data={data}
        className="mt-2"
        valueFormatter={currencyFormatter}
        onValueChange={(item: BreakdownData) => {
          DataTableState.set(s => ({
            ...s,
            search: item?.name, columnFilters: [],
          }))
        }}
      />
    </div>
  )
}


import { capitalCase } from "change-case"
import { useState } from "preact/compat"
import { DataTableState } from "@/deductions/table_state.tsx"
import { useAsyncEffect } from "@/utils"
import { api_fetch } from "@/api/client.tsx"
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { ActiveFilterIcon } from "@/global_filter/active_filter_icon"

export const ALL_DISTRIBUTORS_OPTION = "All Distributors"

export function formatDistributor(name: string): string {
  if (!name) {
    return ""
  } else if (name === ALL_DISTRIBUTORS_OPTION) {
    return ALL_DISTRIBUTORS_OPTION
  }
  const lower = name.toLowerCase()
  if (lower.includes("kehe")) {
    return "KeHE"
  } else if (lower.includes("unfi")) {
    return "UNFI"
  } else if (lower.includes("cands")) {
    return "C&S"
  } else if (lower.includes("heb")) {
    return "HEB"
  } else if (lower.includes("albertsons")) {
    return "Albertson's"
  } else if (lower.includes("demoulas")) {
    return "Demoula's"
  } else if (lower.includes("cvs")) {
    return "CVS"
  } else if (lower.includes("geyser")) {
    return "Big Geyser"
  } else {
    return capitalCase(name)
  }
}

export function DistributorFilter() {
  const [choices, setChoices] = useState<string[]>([])
  const distributor = DataTableState.use(s => s.distributor) ?? ALL_DISTRIBUTORS_OPTION

  function handleDistributorChange(distributor: string) {
    if (distributor === ALL_DISTRIBUTORS_OPTION) {
      DataTableState.set(s => ({ ...s, distributor: null }))
    } else {
      DataTableState.set(s => ({ ...s, distributor }))
    }
  }

  useAsyncEffect(async () => {
    let opts = { use_cache: true }
    const res = await api_fetch<string[]>("/deduction/distributors", opts)
    if (res.ok) {
      setChoices([ALL_DISTRIBUTORS_OPTION, ...res.value.data])
    }
  }, []) // Empty dependency array since we only need to fetch once
  let active = distributor !== ALL_DISTRIBUTORS_OPTION
  return <div className="font-medium">
    <Select onValueChange={handleDistributorChange}>
      <SelectTrigger className="w-full ">
        <SelectValue placeholder={formatDistributor(distributor)} />
        {active && <ActiveFilterIcon />}
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {choices.map((item, idx) => (
            <SelectItem key={idx} value={item as string}>
              <div className="flex items-center">
                <span>{formatDistributor(item)}</span>
              </div>
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  </div>
}

if (import.meta.vitest) {
  describe("formatDistributor", () => {
    it("should return \"KeHE\" for names including \"kehe\"", () => {
      expect(formatDistributor("kehe distributors")).toBe("KeHE")
      expect(formatDistributor("KEHE")).toBe("KeHE")
    })
    it("should return \"UNFI\" for names including \"unfi\"", () => {
      expect(formatDistributor("unfi wholesale")).toBe("UNFI")
      expect(formatDistributor("UNFI")).toBe("UNFI")
    })
    it("should return capitalized case for other names", () => {
      expect(formatDistributor("other distributor")).toBe("Other Distributor")
    })
    it("should return an empty string for empty input", () => {
      expect(formatDistributor('')).toBe('')
    })
  })
}
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Badge } from "@/components/ui/badge"
import { capitalCase } from "change-case"

interface SimpleSelectProps {
  value: string
  onChange: (newStatus: string) => void
  values: { [key: string]: string }
  icons?: { [key: string]: React.ReactNode }
}

export const SimpleSelect = ({
  value,
  onChange,
  values: statusValue,
  icons: statusIcons = {},
}: SimpleSelectProps) => {
  async function handleOnChange(newStatus: string) {
    onChange(newStatus)
  }

  const Icon = statusIcons[value.toLowerCase()] || null

  return (
    <Select onValueChange={handleOnChange}>
      <SelectTrigger className="w-fit p-0 m-0 border-none bg-transparent justify-center">
        <SelectValue
          placeholder={
            <Badge
              className="px-1 pr-2 mr-1 rounded-sm text-center"
              variant={value.toLowerCase() as any}>
              {Icon}
              {capitalCase(value)}
            </Badge>
          }></SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {Object.entries(statusValue).map(([statusOptionKey, statusOptionValue], idx) => (
            <SelectItem key={idx} value={statusOptionKey}>
              <div className="flex items-center">
                <Badge
                  className="px-1 pr-2 mr-1 rounded-sm text-center"
                  variant={statusOptionKey as any}>
                  {statusIcons[statusOptionKey] && statusIcons[statusOptionKey]}
                  {statusOptionValue}
                </Badge>
              </div>
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

import { DeductionResponse } from "@/api/deduction.tsx"

const BASE_URL = window.location.origin

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).catch(err => {
    console.error("Failed to copy: ", err)
  })
}

type AccountingHeaderMap = Record<string, Record<string, string>>
const ACCOUNTING_HEADER_MAP: AccountingHeaderMap = {
  "01j0vbrzjm2nfg26hqnqhk_8hc7": {
    invoice_amount: "UnitPrice",
    reason_code: "Name",
    reason_code_type: "Type",
    expense_account: "ExpenseAccount",
    income_account: "IncomeAccount",
  },
}

export function buildCsv(filteredData: Record<string, any>[], org_id: string): string {
  const HEADER_MAP = ACCOUNTING_HEADER_MAP[org_id] || {}

  // Create header row using the HEADER_MAP or object keys
  const headers = Object.keys(filteredData[0] || {})
  const headerRow = headers.map(header => `"${HEADER_MAP[header] || header}"`).join(",")

  // Create body rows
  const body = filteredData
    .map(item => {
      return headers
        .map(header => {
          const cellValue = item[header]
          return `"${cellValue != null ? cellValue : ""}"`
        })
        .join(",")
    })
    .join("\n")

  return [headerRow, body].join("\n")
}

export function downloadCsv(csvData: string, filename: string) {
  const blob = new Blob([csvData], { type: "text/csv" })
  const url = URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = url

  // Format the download filename with the current date
  const today = new Date()
  let year = today.getFullYear()
  let month = String(today.getMonth() + 1).padStart(2, "0")
  let day = String(today.getDate()).padStart(2, "0")
  const formattedDate = `${year}-${month}-${day}`
  a.download = `${filename}_${formattedDate}.csv`
  // Trigger the download and clean up the URL object
  a.click()
  URL.revokeObjectURL(url)
}

// Add this type to help with object column unpacking
type UnpackConfig = {
  path: string[]
  prefix?: string
  fields?: string[]
}

// Rename to better reflect its purpose
const SPLIT_UNPACK_COLUMNS: Record<string, UnpackConfig> = {
  reason_codes: {
    path: [], // No longer need path since we'll handle each array item
    prefix: "reason_code_",
    fields: [
      "expense_account",
      "name",
      "number",
      "actor",
      "customer_name",
      "amount",
      "is_split",
      "code_description",
      "retailer_name",
    ],
  },
}

// Add portal link based on current page context
function addPortalLink(item: any): string {
  const isAccountingPage = window.location.pathname.includes('split') || window.location.pathname.includes('accounting')
  const id = item.deduction_id ?? item.id
  return `${BASE_URL}/${isAccountingPage ? "split" : "deduction"}/${id}`
}

export function collectDownloadData<T>(data: T[]) {
  const filteredData = []
  const INTERNAL_KEYS = [
    "created_at",
    "updated_at",
    "raw_data",
    "s3_uri",
    "backup_s3_uri",
    "check_s3_uri",
    "repayment_for",
    "is_repayment",
    "is_prepayment",
    "notes",
    "document",
    "user_files",
    "task",
  ]

  for (const item of data) {
    let hasAddedRow = false

    // Process each possible split field
    for (const [splitKey, config] of Object.entries(SPLIT_UNPACK_COLUMNS)) {
      const arrayValue = item[splitKey as keyof typeof item]

      if (Array.isArray(arrayValue) && arrayValue.length > 0) {
        // Create a row for each item in the array
        for (const arrayItem of arrayValue) {
          const baseItem = processItem(item, INTERNAL_KEYS, [splitKey])
          baseItem.portal_link = addPortalLink(item)
          
          // Add the split field's properties with prefix
          for (const objKey in arrayItem) {
            if (!config.fields || config.fields.includes(objKey)) {
              const newKey = config.prefix ? `${config.prefix}${objKey}` : objKey
              baseItem[newKey] = arrayItem[objKey]
            }
          }
          
          filteredData.push(baseItem)
        }
        hasAddedRow = true
        break // Only process the first split field found
      }
    }

    // If no split fields were processed, add the item as is
    if (!hasAddedRow) {
      const filteredItem = processItem(item, INTERNAL_KEYS)
      filteredItem.portal_link = addPortalLink(item)
      filteredData.push(filteredItem)
    }
  }

  return filteredData
}

// Helper function to process a single item
function processItem(
  item: any, 
  internalKeys: string[], 
  excludeKeys: string[] = []
): Record<string, any> {
  const filteredItem: Record<string, any> = {}

  for (const key in item) {
    if (
      Object.prototype.hasOwnProperty.call(item, key) &&
      !key.endsWith("_id") &&
      !internalKeys.includes(key) &&
      !excludeKeys.includes(key) &&
      key !== "id"
    ) {
      filteredItem[key] = item[key]
    }
  }

  return filteredItem
}

export function convertBackupToCsv(data: Record<string, number | string | boolean>[]) {
  if (!data || !data.length) {
    return ""
  }

  // Get all headers
  const allHeaders = Object.keys(data[0])
  
  // Filter out headers where all values are null, undefined, or empty string
  const headers = allHeaders.filter(header => {
    return data.some(item => {
      const value = item[header]
      return value !== null && value !== undefined && value !== ""
    })
  })

  const headerRow = headers.join(",")

  const dataRows = data
    .map(item => {
      return headers
        .map(header => {
          const cellValue = item[header]
          return `"${cellValue != null ? cellValue : ""}"`
        })
        .join(",")
    })
    .join("\n")

  return [headerRow, dataRows].join("\n")
}

const TVI_PREFIXES = ["vcna", "vsup", "vcpn", "vonl", "viap"]

export function getTargetUrl(ded: DeductionResponse): string {
  if (TVI_PREFIXES.some(prefix => ded.invoice_number?.toLowerCase().startsWith(prefix))) {
    let modified = ded.invoice_number
      .split(/[-:]/)[0]
      .replace(/[^0-9]/g, "")
    return `https://tvi.partnersonline.com/quick-search${modified}`
  } else if (ded.invoice_number?.toLowerCase().startsWith("cb")) {
    return "https://apreports.partnersonline.com/credit_debit"
  } else {
    return "https://www.partnersonline.com/page/help/NDI0"
  }
}

if (import.meta.vitest) {
  describe("getTargetUrl", () => {
    it.each([
      ["VCNA0003420106-06", "https://tvi.partnersonline.com/quick-search0003420106"],
      ["VSUP0002549886-01", "https://tvi.partnersonline.com/quick-search0002549886"],
      ["VCPN0002537204-01", "https://tvi.partnersonline.com/quick-search0002537204"],
      ["VONL00253720-101", "https://tvi.partnersonline.com/quick-search00253720"],
      ["VIAP3490811", "https://tvi.partnersonline.com/quick-search3490811"],
      ["VIAP3471694:2024-06-01", "https://tvi.partnersonline.com/quick-search3471694"],
    ])("should return correct TVI URL for %s", (invoiceNumber, expectedUrl) => {
      const row = { invoice_number: invoiceNumber } as any
      expect(getTargetUrl(row)).toBe(expectedUrl)
    })

    it("should return credit/debit URL for CB prefix", () => {
      const row = { invoice_number: "CB-123" } as any
      expect(getTargetUrl(row)).toBe("https://apreports.partnersonline.com/credit_debit")
    })

    it("should return help URL for other cases", () => {
      const row = { invoice_number: "OTHER-123" } as any
      expect(getTargetUrl(row)).toBe("https://www.partnersonline.com/page/help/NDI0")
    })

    it("should handle lowercase invoice numbers", () => {
      const row = { invoice_number: "vcna123" } as any
      expect(getTargetUrl(row)).toBe("https://tvi.partnersonline.com/quick-search123")
    })

    it("should handle undefined invoice number", () => {
      const row = { } as any
      expect(getTargetUrl(row)).toBe("https://www.partnersonline.com/page/help/NDI0")
    })
  })

  describe("convertBackupToCsv", () => {
    it("should convert an array of objects to CSV string", () => {
      const data = [
        { name: "John", age: 30, city: "New York" },
        { name: "Jane", age: 25, city: "Los Angeles" },
      ]
      const expected = 'name,age,city\n"John","30","New York"\n"Jane","25","Los Angeles"'
      expect(convertBackupToCsv(data)).toBe(expected)
    })

    it("should handle empty array", () => {
      expect(convertBackupToCsv([])).toBe("")
    })

    it("should handle objects with boolean values", () => {
      const data = [
        { name: "John", isStudent: true },
        { name: "Jane", isStudent: false },
      ]
      const expected = 'name,isStudent\n"John","true"\n"Jane","false"'
      expect(convertBackupToCsv(data)).toBe(expected)
    })

    it("should handle objects with special characters in values", () => {
      const data = [
        { name: 'John "Doe"', description: "Likes to use, commas" },
        { name: "Jane", description: "No special characters" },
      ]
      const expected = 'name,description\n"John "Doe"","Likes to use, commas"\n"Jane","No special characters"'
      expect(convertBackupToCsv(data)).toBe(expected)
    })

    it("should remove columns that are entirely empty, null, or undefined", () => {
      const data = [
        { name: "John", emptyStr: "", nullVal: null, undefinedVal: undefined, age: 30 },
        { name: "Jane", emptyStr: "", nullVal: null, undefinedVal: undefined, age: 25 },
      ]
      const expected = 'name,age\n"John","30"\n"Jane","25"'
      // @ts-ignore
      expect(convertBackupToCsv(data)).toBe(expected)
    })

    it("should keep columns that have at least one non-empty value", () => {
      const data = [
        { name: "John", col1: "", col2: null, col3: "value" },
        { name: "Jane", col1: "", col2: "data", col3: "" },
      ]
      const expected = 'name,col2,col3\n"John","","value"\n"Jane","data",""'
      // @ts-ignore
      expect(convertBackupToCsv(data)).toBe(expected)
    })

    it("should handle mixed data types in columns", () => {
      const data = [
        { name: "John", value: 123, empty: "" },
        { name: "Jane", value: "string", empty: null },
      ]
      const expected = 'name,value\n"John","123"\n"Jane","string"'
      // @ts-ignore
      expect(convertBackupToCsv(data)).toBe(expected)
    })
  })

  describe("collectDownloadData", () => {
    beforeEach(() => {
      // Mock window.location
      Object.defineProperty(window, 'location', {
        value: {
          pathname: '/deductions',
          origin: 'http://localhost:3000'
        },
        writable: true
      })
    })

    it("should use deduction_id when available", () => {
      const input = [
        { id: "999", deduction_id: "123", name: "John" },
        { id: "888", deduction_id: "456", name: "Jane" },
      ]
      const result = collectDownloadData(input)
      
      expect(result[0].portal_link).toBe("http://localhost:3000/deduction/123")
      expect(result[1].portal_link).toBe("http://localhost:3000/deduction/456")
    })

    it("should fall back to id when deduction_id is not available", () => {
      const input = [
        { id: "123", name: "John" },
        { id: "456", name: "Jane" },
      ]
      const result = collectDownloadData(input)
      
      expect(result[0].portal_link).toBe("http://localhost:3000/deduction/123")
      expect(result[1].portal_link).toBe("http://localhost:3000/deduction/456")
    })

    it("should add split portal links when on accounting page", () => {
      // Update mock location to accounting page
      Object.defineProperty(window, 'location', {
        value: {
          pathname: '/accounting',
          origin: 'http://localhost:3000'
        },
        writable: true
      })

      const input = [
        { id: "999", deduction_id: "123", name: "John" },
        { id: "888", deduction_id: "456", name: "Jane" },
      ]
      const result = collectDownloadData(input)
      
      expect(result[0].portal_link).toBe("http://localhost:3000/split/123")
      expect(result[1].portal_link).toBe("http://localhost:3000/split/456")
    })

    it("should add portal links to split rows", () => {
      const input = [{
        id: "999",
        deduction_id: "123",
        name: "John",
        reason_codes: [
          { name: "RC1", amount: 100 },
          { name: "RC2", amount: 200 },
        ]
      }]
      const result = collectDownloadData(input)
      
      expect(result.length).toBe(2)
      expect(result[0].portal_link).toBe("http://localhost:3000/deduction/123")
      expect(result[1].portal_link).toBe("http://localhost:3000/deduction/123")
      expect(result[0].reason_code_name).toBe("RC1")
      expect(result[1].reason_code_name).toBe("RC2")
    })

    it("should preserve existing data while adding portal links", () => {
      const input = [{
        id: "999",
        deduction_id: "123",
        name: "John",
        status: "active",
        amount: 100
      }]
      const result = collectDownloadData(input)
      
      expect(result[0]).toEqual({
        name: "John",
        status: "active",
        amount: 100,
        portal_link: "http://localhost:3000/deduction/123"
      })
    })

    it("should handle missing ids", () => {
      const input = [{ name: "John" }]
      const result = collectDownloadData(input)
      
      expect(result[0].portal_link).toBe("http://localhost:3000/deduction/undefined")
    })

    it("should handle null deduction_id", () => {
      const input = [{ id: "123", deduction_id: null, name: "John" }]
      const result = collectDownloadData(input)
      
      expect(result[0].portal_link).toBe("http://localhost:3000/deduction/123")
    })
  })

  describe("build_csv function", () => {
    const mockDeductionResponse: DeductionResponse[] = [
      {
        invoice_amount: 100,
        reason_codes: [
          {
            name: "mock-reason-code-name",
            expense_account: "mock-expense-account",
            actor: "mock-actor",
            amount: 100,
            customer_name: "mock-customer-name",
            retailer_name: "mock-retailer-name",
            code_description: "mock-code-description",
            // @ts-ignore
            number: 123,
          },
        ],
        source: "mock-source",
        invoice_number: "mock-invoice-number",
        check_number: 123,
        po_number: "mock-po-number",
        retailer_name: "mock-retailer-name",
        category: "mock-category",
        description: "mock-description",
        status_value: "mock-status-value",
      },
    ]

    it("should return a CSV string with mapped headers when org_id exists in HEADER_MAP", () => {
      const org_id = "01j0vbrzjm2nfg26hqnqhk_8hc7"
      const result = buildCsv(collectDownloadData(mockDeductionResponse), org_id)

      const lines = result.split("\n")
      const headers = lines[0]
      const values = lines[1]

      // Check that invoice_amount is mapped to UnitPrice
      expect(headers).toContain('"UnitPrice"')

      // Check that unpacked reason_code fields are present with their prefixes
      expect(headers).toContain('"reason_code_name"')
      expect(headers).toContain('"reason_code_expense_account"')

      // Check values
      expect(values).toContain('"100"') // invoice_amount/UnitPrice
      expect(values).toContain('"mock-reason-code-name"')
      expect(values).toContain('"mock-expense-account"')
    })
  })
}

import gmailLogo from "/img/gmail-logo.png"
import microsoftLogo from "/img/microsoft-logo.jpg"
import {useState} from "preact/compat";
import {api_fetch} from "src/api/client.tsx";
import {Logo} from "src/app/logo.tsx"
import {useLocation} from "wouter-preact";
import {LoginScreen} from "../components/LoginScreen.tsx"
import {Button} from "@/components/ui/button.tsx";
import {DateTime} from "luxon"
import { safeExec, useAsyncEffect } from "@/utils"

interface Integration {
  name: string
  username: string
  /// iso8601
  session_updated_at: string
}

export function EmailIntegration() {
  let [emails, setEmails] = useState<Integration[]>([])
  let [, setLocation] = useLocation()

  function connectEmail(e: MouseEvent) {
    let t = e.currentTarget as HTMLButtonElement
    let name = t.dataset.name
    safeExec(async () => {
      let res = await api_fetch<string>(`/oauth/auth_url`, {
        body: {next: "/setup/email", name},
      })
      if (!res.ok) {
        console.error(res.error)
        return
      }
      window.location.href = res.value.data
    })
  }

  useAsyncEffect(async () => {
    let res = await api_fetch<Integration[]>("/integration")
    if (!res.ok) {
      console.error(res.error)
      return
    }
    let emails = res.value.data
    emails = emails.filter(e => e.name === "gmail" || e.name === "microsoft")
    setEmails(emails)
  }, [])

  return <LoginScreen title="Connect Email Account" logo={<Logo/>}>
    <div>
      <div class="flex justify-center m-3 rounded-md w-full flex-col space-y-4">
        <div class="w-full">
          To pull deductions from UNFI and see dispute information, we need to connect to your email(s).
          <br/>
          <br/>
          Connect all the email accounts that receive information related to disuptes (e.g. orders@your_brand.com or
          accounting@your_brand.com).
        </div>
        <Button className="btn bg-white text-black border flex p-2 hover:bg-slate-300" data-name="gmail"
                onClick={connectEmail}>
          <img src={gmailLogo} alt="Gmail Logo" class="h-6 mr-2"/>
          <div class="grow text-center">Connect Gmail</div>
        </Button>
        <Button className="btn bg-white text-black border flex p-2 hover:bg-slate-300" data-name="microsoft"
                onClick={connectEmail}>
          <img src={microsoftLogo} alt="Microsoft Logo" class="h-6 mr-2"/>
          <div class="grow text-center">Connect Microsoft Office 365</div>
        </Button>
        <Button variant="tertiary" className="btn py-2" onClick={() => setLocation("/deductions")}>
          Finished Adding Emails
        </Button>
        {emails.length > 0 && <div class="w-full">
            <div class="grid grid-cols-2 gap-1">
                <div class="font-semibold">Connected Account</div>
                <div class="font-semibold">Updated At</div>
              {emails.map(e => {
                let d = DateTime.fromISO(e.session_updated_at)
                let updated = d.toLocaleString(DateTime.DATETIME_MED)
                return <>
                  <div class="overflow-hidden">{e.username}</div>
                  <div>{updated}</div>
                </>
              })}
            </div>
        </div>}
      </div>
    </div>
  </LoginScreen>
}

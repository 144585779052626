import { addDays, format, parseISO, subDays } from "date-fns"
import { useEffect, useState } from "preact/hooks"
import { QueryData, api_fetch } from "@/api"
import { ChartData, MonthlyBreakdownChartBase, RawData } from "./monthly_breakdown_base"
import { formatDistributor, toISODateString } from "@/utils"

interface MonthlyBreakdownRawData extends RawData {
  distributor: string
  total_sales: number
  total_deductions: number
}

export function MonthlyBreakdownChart() {
  const [rawData, setRawData] = useState<MonthlyBreakdownRawData[]>([])
  const [chartData, setChartData] = useState<ChartData[]>([])
  const [distributors, setDistributors] = useState<string[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetchData() {
      try {
        const endDate = new Date()
        const startDate = subDays(new Date(), 365)
        let res = await api_fetch<QueryData>(`/deductions/query`, {
          params: {
            query_name: "monthly_breakdown",
            start_date: toISODateString(startDate),
            end_date: toISODateString(endDate),
          },
        })

        if (!res.ok) {
          throw new Error("Failed to fetch data")
        }

        const headerIndex = res.value.data.headers.reduce(
          (acc, header, index) => {
            acc[header] = index
            return acc
          },
          {} as { [key: string]: number }
        )

        const data = res.value.data.rows.map(row => {
          const parsedDate = parseISO(row[headerIndex.month] as string)
          const correctedDate = addDays(parsedDate, 1) // Adjust for off-by-one error
          return {
            month: format(correctedDate, "MMM yyyy"),
            distributor: row[headerIndex.distributor] as string,
            total_sales: Number(row[headerIndex.total_sales]),
            total_deductions: Number(row[headerIndex.total_deductions]),
          }
        })

        setRawData(data)
        setDistributors([...new Set(data.map(item => item.distributor))])
        setLoading(false)
      } catch (error) {
        console.error("Error fetching data:", error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    function processData() {
      const grouped: { [key: string]: ChartData } = {}

      rawData.forEach(item => {
        if (!grouped[item.month]) {
          grouped[item.month] = { month: item.month }
        }
        grouped[item.month][`${item.distributor}_sales`] = item.total_sales
        grouped[item.month][`${item.distributor}_deductions`] = item.total_deductions
      })

      setChartData(Object.values(grouped))
    }

    processData()
  }, [rawData])

  if (loading) {
    return <div>Loading...</div>
  }

  const colors = {
    kehe: {
      sales: "#81c784", // green-300
      deductions: "#388e3c", // green-700
    },
    unfi: {
      sales: "#4db6ac", // teal-300
      deductions: "#009688", // teal-700
    },
    target: {
      sales: "#ef9a9a", // red-300
      deductions: "#e53935", // red-700
    },
    ahold: {
      sales: "#9fa8da", // indigo-300
      deductions: "#3949ab", // indigo-700
    },
    ab: {
      sales: "#ce93d8", // purple-300
      deductions: "#7b1fa2", // purple-700
    },
    cands: {
      sales: "#90caf9", // blue-300
      deductions: "#1976d2", // blue-700
    },
    biggeyser: {
      sales: "#ffcc80", // orange-300
      deductions: "#f57c00", // orange-700
    },
    cvs: {
      sales: "#a5d6a7", // light-green-300
      deductions: "#388e3c", // light-green-700
    },
    polar: {
      sales: "#80deea", // cyan-300
      deductions: "#0097a7", // cyan-700
    },
    albertsons: {
      sales: "#fff176", // yellow-300
      deductions: "#fbc02d", // yellow-700
    },
    walmart: {
      sales: "#f48fb1", // pink-300
      deductions: "#c2185b", // pink-700
    },
    hannaford: {
      sales: "#bcaaa4", // brown-300
      deductions: "#5d4037", // brown-700
    },
    heb: {
      sales: "#e6ee9c", // lime-300
      deductions: "#afb42b", // lime-700
    },
    kroger: {
      sales: "#b39ddb", // deep-purple-300
      deductions: "#512da8", // deep-purple-700
    },
    costco: {
      sales: "#ffab91", // deep-orange-300
      deductions: "#e64a19", // deep-orange-700
    },
    demoulas: {
      sales: "#9575cd", // deep-purple-300
      deductions: "#512da8", // deep-purple-700
    },
  }

  function getColor(distributor: string, type: "sales" | "deductions") {
    if (distributor.toLowerCase() in colors) {
      return colors[distributor.toLowerCase() as keyof typeof colors][type]
    }
    // Fallback colors for any other distributors
    return type === "sales" ? "#8884d8" : "#0088fe"
  }

  const chartConfig = distributors.reduce((config, distributor) => {
    const formattedDistributor = formatDistributor(distributor)
    config[`${distributor}_sales`] = {
      label: `${formattedDistributor} Sales`,
      color: getColor(distributor, "sales"),
    }
    config[`${distributor}_deductions`] = {
      label: `${formattedDistributor} Deductions`,
      color: getColor(distributor, "deductions"),
    }
    return config
  }, {} as any)

  const dataKeys = distributors.flatMap(distributor => [
    `${distributor}_sales`,
    `${distributor}_deductions`,
  ])

  return (
    <MonthlyBreakdownChartBase
      title="Monthly Sales vs Deductions by Distributor"
      titleTooltip="Showing total sales and deductions for each distributor over the last 12 months"
      chartData={chartData}
      chartConfig={chartConfig}
      dataKeys={dataKeys}
    />
  )
}

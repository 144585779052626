import { useState } from "preact/hooks"
import { Logo } from "src/app/logo.tsx"
import { api_fetch } from "../api/client.tsx"
import { LoginScreen } from "../components/LoginScreen.tsx"
import boxLogo from "/img/box-logo.png"
import { useAsyncEffect } from "@/utils"
import { LoadingSpinner } from "@/app/loading"

export function SpsIntegration() {
  const [oauthUri, setOauthUri] = useState<string | undefined>()

  useAsyncEffect(async () => {
    const res = await api_fetch<string>("/oauth/auth_url", {
      method: "POST",
      body: { next: "/deductions", name: "sps" },
    })
    if (!res.ok) {
      console.error(res.error)
      return
    }

    let qs = new URLSearchParams()
    qs.set("audience", "api://api.spscommerce.com/")
    let oauthUri = res.value.data + `&${qs.toString()}`
    setOauthUri(oauthUri)
  }, [])

  if (!oauthUri) {
    return <LoadingSpinner color="blue-500" />
  }

  return <LoginScreen title="" logo={<Logo />}>
    <div>
      <div class="flex justify-center m-3 rounded-md">
        <img
          src={boxLogo}
          alt="Box Logo"
          class="h-16"
        />
      </div>
      {oauthUri && (
        <>
          <div className="my-4 rounded-lg">
            <div>
              <p className="text-gray-600">
                Ok this is the last step! Connect SPS so we can automatically pull in invoice and PO backup for you.
              </p>
            </div>
          </div>

          <div className="flex border-t border-gray-100 pb-2">
            <a
              className="btn w-full text-center bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-md transition duration-300 ease-in-out"
              href={oauthUri}
            >
              Connect SPS 
            </a>
          </div>
        </>
      )}
    </div>
  </LoginScreen>
}

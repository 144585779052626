import { actionsColumn, checkAmountColumn, checkNumberColumn, DeductionTableColumn, discountAmountColumn, distributorColumn, filesColumn, invoiceAmountColumn, invoiceNumberColumn, paymentDaysColumn, selectColumn, statusColumn, taskColumn } from "@/deductions/table/columns";
import { createDateColumn } from "@/deductions/table/date_column";


export const INVOICE_COLUMNS: DeductionTableColumn[] = [
  selectColumn,
  createDateColumn("invoice_date", "Invoice Date"),
  invoiceNumberColumn,
  invoiceAmountColumn,
  distributorColumn,
  filesColumn,
  discountAmountColumn,
  createDateColumn("check_date", "Check Date", { enableGlobalFilter: true, enableHiding: true }),
  createDateColumn("payment_due_date", "Payment Due Date", { enableGlobalFilter: true, enableHiding: true }),
  paymentDaysColumn,
  checkNumberColumn,
  checkAmountColumn,
  statusColumn,
  actionsColumn,
  taskColumn,
//   todo: difference between check date and invoice date in days or is that not the payment date??
]

import { SimpleSelect } from "@/components/simple_select.tsx"
import { api_fetch } from "@/api/client.tsx"
import { toast } from "@/components/ui/use-toast.ts"
import { DeductionResponse } from "@/api/deduction.tsx"
import { STATUS_ICONS } from "./table/columns.tsx"

export const STATUS_VALUES = {
  new: "New",
  validated: "Validated",
  disputable: "Disputable",
  backup_requested: "Backup Requested",
  backup_received: "Backup Received",
  review: "Review",
  disputed: "Disputed",
  won: "Won",
  lost: "Lost",
}

interface StatusSelectProps {
  deduction: DeductionResponse
  onStatusChange?: () => void
}

export function StatusSelect({ deduction, onStatusChange }: StatusSelectProps) {
  let initialValue = deduction.status_value || "new"

  const handleOnChange = async (_s: string) => {
    let newStatus = _s as keyof typeof STATUS_VALUES
    const res = await api_fetch(`/deductions/status`, {
      method: "POST",
      body: {
        deduction_id: deduction.id,
        status: newStatus,
      },
    })

    if (res.ok) {
      console.log("Status updated successfully")
      toast({
        title: "Status Updated",
        description: `Status changed to ${STATUS_VALUES[newStatus]}`,
      })
      onStatusChange?.()
    } else {
      console.error("Failed to update status")
    }
  }

  return (
    <SimpleSelect
      value={initialValue}
      onChange={handleOnChange}
      values={STATUS_VALUES}
      icons={STATUS_ICONS}
    />
  )
}

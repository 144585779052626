import { DeductionResponse } from "@/api/deduction.tsx"
import { ColumnHeader } from "./column_header.tsx"
import { TableColumn } from "./columns.tsx"
import { dateFormatter, FULL_DATE_FORMATTER } from "@/utils"

export function createDateColumn(
  accessorKey: string,
  title: string,
  options?: {
    enableHiding?: boolean
    enableGlobalFilter?: boolean
  }
): TableColumn<DeductionResponse> {
  return {
    accessorKey: accessorKey as keyof DeductionResponse,
    enableHiding: options?.enableHiding ?? false,
    enableGlobalFilter: options?.enableGlobalFilter ?? false,
    header: ({ column }) => <ColumnHeader column={column} title={title} />,
    cell: cellDateFormatter,
    // @ts-ignore
    filterFn: "dateFilter" as const,
  }
}

export const cellDateFormatter = ({ getValue }: { getValue: () => any }) => {
  const value = getValue()
  return dateFormatter(value, FULL_DATE_FORMATTER)
}

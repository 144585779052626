import {
  DeductionResponse,
  EmailMessage,
  DisputeMessage as FreshdeskMessage,
} from "@/api/deduction.tsx"
import { useEffect, useState } from "preact/compat"

import { Backup } from "@/api/backup.tsx"
import { api_fetch } from "@/api/client.tsx"
import { User } from "@/api/user.tsx"
import { LoadingSpinner } from "@/app/loading"
import { DetailNavbar } from "@/app/navbar"
import { Button } from "@/components/ui/button.tsx"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx"
import { toast } from "@/components/ui/use-toast.ts"
import { AssignmentForm } from "@/deductions/table/assignment_form.tsx"
import { canDispute, formatDistributor, useAsyncEffect } from "@/utils"
import { CircleCheck, ShieldAlert } from "lucide-react"
import { StatusFlag } from "../status_state.tsx"
import { FileDisputeDrawer } from "../table/actions/file_dispute_drawer.tsx"
import { backup_columns } from "./backup_columns.tsx"
import { BackupTable } from "./backup_table.tsx"
import { Conversations } from "./conversations.tsx"
import { DeductionCard } from "./deduction_card.tsx"
import { DeductionFile, Files } from "./files.tsx"
import { Updates } from "./updates.tsx"


export function getFiles(
  deduction: DeductionResponse,
  emails: EmailMessage[],
  messages: FreshdeskMessage[]
): DeductionFile[] {
  let files: DeductionFile[] = []
  for (const email of emails) {
    for (const attachment of email.attachments) {
      let s3_uri = attachment.s3_uri
      if (s3_uri == deduction.check_s3_uri) {
        // bullshit hack to try to have the backup date be more accurate.
        // see e.g. /deduction/01j47kppgp1pkdrg4tpw1h_za0a
        continue
      }
      if (s3_uri === deduction.backup_s3_uri) {
        // bullshit hack to try to have the backup date be more accurate.
        // see e.g. /deduction/01j47kppgp1pkdrg4tpw1h_za0a
        deduction.created_at = email.date
        continue
      }
      files.push({
        display_name: attachment.s3_uri.split("/").pop() || "Email Attachment",
        date: email.date,
        s3_uri,
        deduction_id: deduction.id,
        // actor_name: "UNFI", // todo: get actor name from email when
      })
    }
  }
  for (const message of messages) {
    for (const s3_uri of message.attachment_s3_paths ?? []) {
      if (message.sequence === 0) {
        continue
      }
      if (s3_uri === deduction.check_s3_uri) {
        continue
      }
      if (s3_uri === deduction.backup_s3_uri) {
        // bullshit hack to try to have the backup date be more accurate.
        // see e.g. /deduction/01j47kppgp1pkdrg4tpw1h_za0a
        deduction.created_at = message.sent_at
        continue
      }
      files.push({
        display_name: "Freshdesk Backup", // maybe: s3_uri.split("/").pop() ||
        date: message.sent_at,
        s3_uri,
        deduction_id: deduction.id,
        actor_name: "KeHE",
      })
    }
  }
  if (deduction.backup_s3_uri) {
    files.push({
      display_name: `${formatDistributor(deduction.source)} Backup`,
      // TODO should be date of the backup request.
      date: deduction.created_at,
      s3_uri: deduction.backup_s3_uri,
      deduction_id: deduction.id,
    })
  }
  if (deduction.check_s3_uri) {
    files.push({
      display_name: `${formatDistributor(deduction.source)} Check`,
      date: deduction.check_date,
      s3_uri: deduction.check_s3_uri,
      deduction_id: deduction.id,
    })
  }
  if (deduction.user_files) {
    for (const user_file of deduction.user_files) {
      files.push({
        display_name: user_file.display_name,
        date: user_file.date,
        s3_uri: user_file.s3_uri,
        deduction_id: deduction.id,
        actor_name: user_file.actor_name,
      })
    }
  }
  files = files.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
  files = files.filter((f, i, self) => i === self.findIndex(f2 => f.s3_uri === f2.s3_uri))
  return files
}

interface DeductionDetailProps {
  params: { deduction_id: string }
}
export function DeductionDetail({ params }: DeductionDetailProps) {
  const deduction_id = params.deduction_id
  const [deduction, setDeduction] = useState<DeductionResponse | null>(null);
  const [loading, setLoading] = useState(true)
  const [backupLoading, setBackupLoading] = useState(true)
  const [backup, setBackup] = useState<Backup[]>([])
  const [emails, setEmails] = useState<EmailMessage[]>([])
  const [emailsLoading, setEmailsLoading] = useState(true)
  const [messages, setMessages] = useState<FreshdeskMessage[]>([])
  const [messagesLoading, setMessagesLoading] = useState(true)
  const [files, setFiles] = useState<DeductionFile[]>([])
  // todo: query for deduction and backup separately?? probably ok to just get those pieces of data at once
  const statusFlag = StatusFlag.use(sf => sf!)

  useAsyncEffect(async () => {
    if (deduction_id !== deduction?.id) {
      setLoading(true)
      setEmailsLoading(true)
      setMessagesLoading(true)
      setBackupLoading(true)
    }
    const deductionRes = await api_fetch<DeductionResponse>(`/deduction/${deduction_id}`)
    const backupRes = await api_fetch<Backup[]>(`/deduction/${deduction_id}/backup`)
    const emailRes = await api_fetch<EmailMessage[]>(`/deduction/${deduction_id}/emails`)
    const messagesRes = await api_fetch<FreshdeskMessage[]>(`/deduction/${deduction_id}/messages`)

    if (!deductionRes.ok) {
      setLoading(false)
      throw new Error("Failed to fetch deduction")
    }

    setDeduction(deductionRes.value.data)
    if (backupRes.ok) {
      setBackup(backupRes.value.data)
    }
    setBackupLoading(false)

    if (emailRes.ok) {
      setEmails(emailRes.value.data)
    }

    if (messagesRes.ok) {
      setMessages(messagesRes.value.data)
    }
    setEmailsLoading(false)
    setMessagesLoading(false)
    setLoading(false)
  }, [statusFlag, deduction_id])

  if (!deduction || loading || emailsLoading || messagesLoading) {
    return <LoadingSpinner color="plue-500" />
  }

  useEffect(() => {
    setFiles(getFiles(Object.assign({}, deduction), emails, messages))
  }, [emails, deduction, messages])

  function handleDeductionUpdate(updated: DeductionResponse) {
    setDeduction(updated)
  }

  let title = deduction.invoice_number
  if (title.match(/^[0-9]+$/)) {
    title = `#${title}`
  }
  let breadcrumbs = [
    { label: "Deductions", href: "/deductions" },
    { href: "#", label: deduction.invoice_number },
  ]
  return (
    <div key={deduction_id}>
      <DetailNavbar items={breadcrumbs} />
      <Header title={title} deduction={deduction} />
      {deduction && (
        <DeductionCard
          deduction={deduction}
          onUpdate={handleDeductionUpdate}
        />
      )}
      {/* TODO: stack the updates and files on top of each other. make convos wider */}
      <div class="columns-2 gap-4 mt-4">
        <div class="flex flex-col gap-4">
          <Updates deduction={deduction} backup={backup[0]} emails={emails} />
          <Files files={files} deductionId={deduction.id} />
        </div>
        <Conversations deduction={deduction} emails={emails} messages={messages} />
      </div>
      <div class="w-full min-h-44">
        <BackupTable loading={backupLoading} columns={backup_columns} data={backup} />
      </div>
    </div>
  )
}

function Header({ title, deduction }: { title: string; deduction: DeductionResponse }) {
  const [assignmentPopoverOpen, setAssignmentPopoverOpen] = useState(false)
  const [users, setUsers] = useState<User[]>([])

  useAsyncEffect(async () => {
    let res = await api_fetch<User[]>("/users")
    if (!res.ok) {
      console.error(`Failed to fetch users`)
      return
    }
    setUsers(res.value.data)
  }, [])

  async function handleTaskAssignment(values: any) {
    let body = [{
      deduction_id: deduction.id,
      user_id: values.user_id,
      task_type: values.taskType,
      backup_type: values.backupType,
      note: values.note,
    }]

    let res = await api_fetch("/tasks", { body })

    if (!res.ok) {
      toast({
        title: "Error",
        description: "Failed to assign task",
      })
      console.error(`Failed to assign task`)
      return
    }

    toast({
      title: "Success",
      description: "Task assigned successfully",
    })
    setAssignmentPopoverOpen(false)
    StatusFlag.set(flag => !flag)
  }

  return (
    <div className="flex items-center justify-between py-2 border-b ">
      <h1 className="text-2xl">{title}</h1>
      <div className="flex gap-2 ml-auto">
        <Popover
          modal={true}
          open={assignmentPopoverOpen}
          onOpenChange={setAssignmentPopoverOpen}>
          <PopoverTrigger
            onClick={(e: MouseEvent) => {
              e.preventDefault()
              e.stopImmediatePropagation()
              setAssignmentPopoverOpen(!assignmentPopoverOpen)
            }}
          >
            <Button variant="outline" className="bg-yellow-500-opacity-10">
              <CircleCheck className="h-4 mr-1 text-yellow-800" />
              <span className="text-yellow-800">Assign task</span>
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[324px]">
            <AssignmentForm users={users} onSubmit={handleTaskAssignment} />
          </PopoverContent>
        </Popover>
        {canDispute(deduction) && (
          <FileDisputeDrawer
            deduction={deduction}
            trigger={
              <Button variant="tertiary">
                <ShieldAlert className="w-4 mr-1" />
                File Dispute
              </Button>
            }
          />
        )}
      </div>
    </div>
  )
}

if (import.meta.vitest) {
  describe("getFiles function", () => {
    function mockDeduction() {
      return {
        id: "deduction1",
        source: "email",
        backup_s3_uri: "s3://backup/123",
        check_s3_uri: "s3://check/456",
        created_at: "2022-01-01",
        check_date: "2022-01-02",
      }
    }
    it("should process email attachments correctly", () => {
      const emails = [
        {
          date: "2022-01-03",
          attachments: [{ s3_uri: "s3://attachments/789" }, { s3_uri: "s3://attachments/101" }],
        },
      ]
      // @ts-ignore
      const result = getFiles(mockDeduction(), emails, [])
      expect(result).toHaveLength(4) // 2 attachments + backup + check
      expect(result[0]).toHaveProperty("display_name", "789")
      expect(result[1]).toHaveProperty("display_name", "101")
    })

    it("should skip email attachments that match backup or check URIs", () => {
      const emails = [
        {
          date: "2022-01-03",
          attachments: [
            { s3_uri: "s3://backup/123" },
            { s3_uri: "s3://check/456" },
            { s3_uri: "s3://attachments/789" },
          ],
        },
      ]
      // @ts-ignore
      const result = getFiles(mockDeduction(), emails, [])
      expect(result).toHaveLength(3) // 1 attachment + backup + check
      expect(result[0]).toHaveProperty("display_name", "789")
    })

    it("should process dispute messages correctly", () => {
      const messages = [
        {
          sent_at: "2022-01-04",
          attachment_s3_paths: ["s3://messages/123", "s3://messages/456"],
          sequence: 1,
        },
      ]
      // @ts-ignore
      const result = getFiles(mockDeduction(), [], messages)
      expect(result).toHaveLength(4) // 2 message attachments + backup + check
      expect(result[0]).toHaveProperty("display_name", "Freshdesk Backup")
      expect(result[1]).toHaveProperty("display_name", "Freshdesk Backup")
    })

    it("should skip dispute messages with sequence 0", () => {
      const messages = [
        {
          sent_at: "2022-01-04",
          attachment_s3_paths: ["s3://messages/123"],
          sequence: 0,
        },
      ]
      // @ts-ignore
      const result = getFiles(mockDeduction(), [], messages)
      expect(result).toHaveLength(2) // Only backup + check
    })

    it("should include backup and check files when present", () => {
      // @ts-ignore
      const result = getFiles(mockDeduction(), [], [])
      expect(result).toHaveLength(2)
      expect(result[0]).toHaveProperty("display_name", "Email Check")
      expect(result[1]).toHaveProperty("display_name", "Email Backup")
    })

    it("should handle missing backup or check URIs", () => {
      const deductionWithoutURIs = { ...mockDeduction, backup_s3_uri: null, check_s3_uri: null }
      // @ts-ignore
      const result = getFiles(deductionWithoutURIs, [], [])
      expect(result).toHaveLength(0)
    })

    it("should sort files by date in descending order", () => {
      const emails = [
        { date: "2022-01-03", attachments: [{ s3_uri: "s3://attachments/789" }] },
        { date: "2022-01-05", attachments: [{ s3_uri: "s3://attachments/101" }] },
      ]
      const messages = [
        { sent_at: "2022-01-04", attachment_s3_paths: ["s3://messages/123"], sequence: 1 },
      ]
      // @ts-ignore
      const result = getFiles(mockDeduction(), emails, messages)
      expect(result).toHaveLength(5)
      expect(result[0]).toHaveProperty("date", "2022-01-05")
      expect(result[1]).toHaveProperty("date", "2022-01-04")
      expect(result[2]).toHaveProperty("date", "2022-01-03")
      expect(result[3]).toHaveProperty("date", "2022-01-02")
      expect(result[4]).toHaveProperty("date", "2022-01-01")
    })

    it("should handle empty inputs", () => {
      // @ts-ignore
      let deduction: DeductionResponse = {
        ...mockDeduction(),
        backup_s3_uri: null,
        check_s3_uri: null,
      }
      const result = getFiles(deduction, [], [])
      expect(result).toHaveLength(0)
    })

    it('should use "Email Attachment" as display name when s3_uri is empty', () => {
      const emails = [
        {
          date: "2022-01-03",
          attachments: [{ s3_uri: "" }],
        },
      ]
      // @ts-ignore
      const result = getFiles(mockDeduction(), emails, [])
      expect(result).toHaveLength(3) // 1 attachment + backup + check
      expect(result[0]).toHaveProperty("display_name", "Email Attachment")
    })
  })
}

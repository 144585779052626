import { useState } from "preact/compat"
import { DataTableState } from "@/deductions/table_state"
import { StatusFlag } from "@/deductions/status_state"
import { currencyFormatter, index_map, useAsyncEffect } from "@/utils"
import { fetch_query_data } from "@/api"
import { LoadingSpinner } from "@/app/loading"
import { BarList } from "@tremor/react"
import { BreakdownData, FALLBACK_COLORS } from "@/components/breakdown"
import { ColumnFiltersState } from "@tanstack/react-table"
import { TransactionType } from "@/global_filter"

export const CATEGORY_COLORS = {
  "Retailer Promotion": "amber",
  "Operational Issues": "emerald",
  "Free Fill": "fuchsia",
  "Distributor Promotion": "sky",
  "Contractual Fees": "rose",
  "Misc": "slate",
  "Freight": "teal",
  "Invoice Discrepancy": "lime",
  "Slotting": "indigo",
  "Spoilage": "emerald",
  "Invoice Adjustment": "lime",
}

export function CategoryBreakdown() {
  const [data, setData] = useState<BreakdownData[]>([])
  const [loading, setLoading] = useState(true)
  const [start, end, distributor] = DataTableState.use(u => [u.startDate, u.endDate, u.distributor])
  const statusFlag = StatusFlag.use(sf => sf!)

  useAsyncEffect(async () => {
    let res = await fetch_query_data("category", { start, end, distributor })
    setLoading(false)
    if (!res.ok) {
      setData([])
      throw new Error("Failed to fetch data")
    }

    let { rows, headers } = res.value
    let headerIndex = index_map(headers)

    rows = rows.filter(row => row[headerIndex.name] !== "Total")
    rows.sort((a, b) => (b[headerIndex.value] as number) - (a[headerIndex.value] as number))

    let data = rows.map((row, index) => {
      const name = row[headerIndex.name] as keyof typeof CATEGORY_COLORS
      return {
        name,
        value: Math.abs(row[headerIndex.value] as number) as number,
        color: CATEGORY_COLORS[name] || FALLBACK_COLORS[index % FALLBACK_COLORS.length],
      }
    })
    setData(data)
  }, [start, end, distributor, statusFlag])

  if (loading || !data) {
    return <LoadingSpinner color="blue" />
  }

  return (
    <div className="w-full">
      <BarList
        data={data}
        className="mt-2"
        valueFormatter={currencyFormatter}
        onValueChange={(item: BreakdownData) => {
          DataTableState.set(s => {
            let columnFilters: ColumnFiltersState = [
              { id: "category", value: item?.name },
            ]
            let search = ""
            let transactionTypes: TransactionType[] = ["deduction"]
            return { ...s, search, columnFilters, transactionTypes}
          })
        }}
      />
    </div>
  )
}


import { DeductionResponse } from "@/api/deduction.tsx"
import { ExternalLinkIcon, ShieldAlert } from "lucide-react"
import { Link, useLocation } from "wouter-preact"
import { FileDisputeDrawer } from "./file_dispute_drawer.tsx"
import { Notes } from "./notes.tsx"
import { canDispute, stopPropagation } from "@/utils"

export function Actions({ deduction }: { deduction: DeductionResponse }) {
  const [location] = useLocation()
  return (
    <div className="flex justify-between items-center gap-1" onClick={stopPropagation}>
      <Notes deduction_id={deduction.id} notes={deduction.notes} />
      {canDispute(deduction) ? (
        <FileDisputeDrawer
          deduction={deduction}
          trigger={<ShieldAlert className={`h-5 w-5 cursor-pointer ${deduction.dispute_id ? "text-yellow-500" : "text-red-600" }`} />}
        />
      ) : (
        <Link to={`/deduction/${deduction.id}`}>
          {" "}
          <ShieldAlert className="h-5 w-5 text-green-500" />{" "}
        </Link>
      )}
      <ExternalLinkIcon
        className="h-5 w-5 cursor-pointer text-primary underline"
        onClick={() =>
          window.open(
            location.includes("accounting")
              ? `/split/${deduction.id}`
              : `/deduction/${deduction.id}`,
            "_blank"
          )
        }
      />
    </div>
  )
}

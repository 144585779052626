import { Input } from "@/components/ui/input.tsx"
import { HTMLAttributes, useEffect, useState } from "preact/compat"


export function DebouncedInput({
                                 value: initialValue,
                                 onChange,
                                 debounce = 500,
                                 ...props
                               }: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<HTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    // @ts-ignore
    <Input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}
  
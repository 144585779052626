import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx"
import { useState } from "preact/compat"
import { api_fetch, Cell, QueryData } from "@/api"
import { TransactionType } from "@/global_filter/transaction_type"
import { StatusBreakdown } from "./status_breakdown.tsx"
import { classes, cn, currencyFormatter, map, toISODateString, useAsyncEffect } from "@/utils"
import { DataTableState } from "@/deductions/table_state.tsx"
import { LoadingSpinner } from "@/app/loading"
import { CategoryBreakdown } from "@/deductions/category"

export function SummaryCards() {
  const [data, setData] = useState<QueryData>()
  const [loading, setLoading] = useState(true)
  const { transactionTypes } = DataTableState.use()
  const [start, end, distributor] = DataTableState.use(s => [s.startDate, s.endDate, s.distributor])

  useAsyncEffect(async () => {
    let res = await api_fetch<QueryData>(`/deductions/query`, {
      params: {
        start_date: map(start, toISODateString),
        end_date: map(end, toISODateString),
        distributor,
        query_name: "summary",
      },
    })
    if (!res.ok) {
      setLoading(false)
      throw new Error("Failed to fetch data")
    }
    setLoading(false)
    setData(res.value.data)
  }, [start, end, distributor])

  function handleSetTransactionTypes(type: TransactionType) {
    if (transactionTypes?.includes(type)) {
      DataTableState.set(s => ({
        ...s,
        transactionTypes: s.transactionTypes?.filter(t => t !== type),
      }))
    } else {
      DataTableState.set(s => ({
        ...s,
        transactionTypes: [...(s.transactionTypes || []), type],
      }))
    }
  }

  if (loading) {
    return <LoadingSpinner color="blue" />
  }

  const row = data?.rows[0]!

  return (
    <div className="grid lg:grid-cols-3 w-full lg:space-x-2 space-y-2 lg:space-y-0">
      <div class="space-y-2">
        <Card
          className={classes("h-fit", transactionTypes?.includes("invoice") && "border-blue-400")}>
          <CardBody
            value={row[0]}
            total={`${row[1]} purchase orders`}
            title="Total Sales"
            valueFormatter={currencyFormatter}
            onClick={() => handleSetTransactionTypes("invoice")}
          />
        </Card>
        <Card
          className={classes("h-fit", transactionTypes?.includes("deduction") && "border-blue-400")}>
          <CardBody
            value={row[2]}
            total={`${row[3]} deductions on invoices`}
            title="Total Deductions"
            valueFormatter={currencyFormatter}
            color="red-800"
            onClick={() => handleSetTransactionTypes("deduction")}
          />
        </Card>
        <Card>
          <CardBody
            value={`${currencyFormatter(Math.abs(row[6] as number))} - ${currencyFormatter(
              Math.abs(row[7] as number),
            )}`}
            total="Estimated"
            title="Winnable Disputes"
            color="green-700"
          />
        </Card>
      </div>
      <Card className="h-fit">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-md font-medium">Deduction Status Breakdown</CardTitle>
          <div>Gross Deductions</div>
        </CardHeader>
        <CardContent>
          <StatusBreakdown />
        </CardContent>
      </Card>
      <Card className="h-fit">
        <CardHeader className="flex space-y-0 pb-2">
          <CardTitle className="text-md font-medium">Deduction Category Breakdown</CardTitle>
        </CardHeader>
        <CardContent>
          <CategoryBreakdown />
        </CardContent>
      </Card>
    </div>
  )
}

interface SummaryCardData {
  title?: string
  color?: string
  total?: Cell
  value: Cell
  valueFormatter?: (value: number) => string
  onClick?: () => void
}

export function CardBody({ title, color, total, value, valueFormatter, onClick }: SummaryCardData) {
  return (
    <div onClick={onClick}>
      {title && (
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
          <CardTitle className="text-md font-medium">{title}</CardTitle>
        </CardHeader>
      )}
      <CardContent>
        <div className={cn("text-3xl font-bold", color && `text-${color}`)}>
          {valueFormatter ? valueFormatter(value as number) : value}
        </div>
        {total && <p className="text-sm text-muted-foreground pt-4">{total}</p>}
      </CardContent>
    </div>
  )
}

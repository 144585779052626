import { Card } from "@/components/ui/card.tsx"
import { Separator } from "@/components/ui/separator.tsx"
import { formatDistanceToNow } from "date-fns"
import { Eye, FilesIcon, PaperclipIcon } from "lucide-react"
import { useEffect, useState } from "preact/hooks"

import { Button } from "@/components/ui/button.tsx"
import { Upload } from "lucide-react"
import { StatusFlag } from "../status_state.tsx"

interface SimpleFileUploaderProps {
  onFileUpload: (files: File[]) => void
}

export function SimpleFileUploader({ onFileUpload }: SimpleFileUploaderProps) {
  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target as HTMLInputElement
    if (target.files) {
      onFileUpload(Array.from(target.files))
    }
  }

  return (
    <div>
      <Button
        variant="tertiary"
        // @ts-ignore
        size="sm"
        onClick={() => document.getElementById("file_upload")?.click()}>
        <Upload className="h-4 w-4 mr-2" />
        Upload
      </Button>
      <input
        type="file"
        id="file_upload"
        name="file_upload"
        multiple
        onChange={handleFileChange}
        className="hidden"
      />
    </div>
  )
}

export interface DeductionFile {
  display_name: string
  date: string
  s3_uri: string
  deduction_id?: string
  actor_name?: string
}

function formatFileSource(file: DeductionFile) {
  return file.display_name
}

export function handleViewS3Uri(s3_uri: string) {
  return function handleClick(e: MouseEvent) {
    e.preventDefault()
    e.stopImmediatePropagation()
    window.open(`/api/_s3/${encodeURIComponent(s3_uri)}`, "_blank")
  }
}

async function uploadFiles(deductionId: string, files: File[]): Promise<DeductionFile[]> {
  const formData = new FormData()
  for (const file of files) {
    formData.append("files", file)
  }

  const response = await fetch(`/api/deduction/${deductionId}/files`, {
    method: "POST",
    credentials: "same-origin",
    body: formData,
  })

  if (!response.ok) {
    throw new Error("Failed to upload files")
  }

  return response.json()
}

interface FilesProps {
  files: DeductionFile[]
  deductionId: string
}

export function Files({ files, deductionId }: FilesProps) {
  async function handleFileUpload(uploadedFiles: File[]) {
    try {
      await uploadFiles(deductionId, uploadedFiles)
      StatusFlag.set(flag => !flag)
    } catch (error) {
      console.error("Error uploading files:", error)
    }
  }

  return (
    <Card className="h-64 mt-1.5 overflow-y-auto">
      <div className="flex justify-between items-center m-4">
        <h2 className="flex text-xl">
          <FilesIcon className="h-6 w-6" />
          <span className="ml-2">Files</span>
        </h2>
        <SimpleFileUploader onFileUpload={handleFileUpload} />
      </div>
      <div className="px-4 w-full flex justify-center">
        <Separator className="w-1/2" />
      </div>
      <div className="flex flex-col gap-4 mt-4 mb-4">
        {files.map((file, i) => (
          <div
            key={i}
            className="flex mt-2 gap-2 items-center ml-5 hover:cursor-pointer pr-5"
            onClick={handleViewS3Uri(file.s3_uri)}>
            <div className="flex-shrink-0">
              <div className="inline-flex items-center justify-center border p-2 rounded-3xl">
                <PaperclipIcon className="text-gray-800 h-5 w-5" />
              </div>
            </div>
            <div className="flex-grow min-w-0">
              <div className="text-lg truncate">{formatFileSource(file)}</div>
              <div className="text-gray-500 truncate">{`Uploaded${
                file.actor_name ? ` by ${file.actor_name}` : ""
              } ${formatDistanceToNow(new Date(file.date), { addSuffix: true })}`}</div>
            </div>
            <div className="flex-shrink-0">
              <Eye className="text-gray-800 h-6 w-6" />
            </div>
          </div>
        ))}
      </div>
    </Card>
  )
}

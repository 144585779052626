import { TaskType } from "@/api/task.tsx"
import { User } from "@/api/user.tsx"
import { Button } from "@/components/ui/button.tsx"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx"
import { Textarea } from "@/components/ui/textarea.tsx"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { formatTaskType } from "../detail/updates.tsx"

const BACKUP_TYPES = [
  "Bill of lading (BOL)",
  "Proof of delivery (POD)",
  "BOL & Pick List",
  "BOL & POD",
  "Email",
  "Purchase Order (PO)",
  "Invoice",
  "Other",
]

const FORM_SCHEMA = z.object({
  user_id: z.string().min(1, { message: "Please select a team member" }),
  taskType: z.string().min(1, { message: "Please select a task type" }),
  backupType: z.string().optional(),
  note: z.string().optional(),
  // dueDate: z.date(),
})

interface AssignmentFormProps {
  users: User[]
  onSubmit: (values: any) => void
}

export function AssignmentForm({ users, onSubmit }: AssignmentFormProps) {
  const form = useForm<z.infer<typeof FORM_SCHEMA>>({
    resolver: zodResolver(FORM_SCHEMA),
    defaultValues: {
      user_id: "",
      taskType: "",
      backupType: "",
      note: "",
    },
  })

  function handleSubmit(values: z.infer<typeof FORM_SCHEMA>) {
    onSubmit(values)
  }

  const selectedTaskType = form.watch("taskType")

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="space-y-6 w-full max-w-md mx-auto">
        <FormField
          control={form.control}
          name="user_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Team member</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a team member" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectGroup>
                    {users.map(user => (
                      <SelectItem key={user.id} value={user.id}>
                        {user.first_name
                          ? `${user.first_name} ${user.last_name}`
                          : user.email}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="taskType"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Task Type</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a task type" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {Object.values(TaskType).map(type => (
                    <SelectItem key={type} value={type}>
                      {formatTaskType(type)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        {selectedTaskType === TaskType.PullBackup && (
          <FormField
            control={form.control}
            name="backupType"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Backup Type</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select type of backup" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {BACKUP_TYPES.map(type => (
                      <SelectItem key={type} value={type}>
                        {type}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {selectedTaskType && (
          <FormField
            control={form.control}
            name="note"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Note</FormLabel>
                <Textarea {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        {/* <FormField
          control={form.control}
          name="dueDate"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Due Date</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant={"outline"}
                      className={classes(
                        "w-full pl-3 text-left font-normal",
                        !field.value ? "text-muted-foreground" : ""
                      )}
                    >
                      {field.value ? (
                        format(field.value, "PPP")
                      ) : (
                        <span>Pick a date</span>
                      )}
                      <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={field.value}
                    onSelect={field.onChange}
                    disabled={(date) =>
                      date < new Date() || date > new Date("2100-01-01")
                    }
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        /> */}

        <Button type="submit" className="w-full" variant="tertiary">
          Submit
        </Button>
      </form>
    </Form>
  )
}

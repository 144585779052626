import { ColumnHeader } from "@/deductions/table/column_header"
import {
  actionsColumn,
  categoryColumn,
  checkAmountColumn,
  checkNumberColumn,
  DeductionTableColumn,
  descriptionColumn,
  distributorColumn,
  filesColumn,
  invoiceAmountColumn,
  invoiceNumberColumn,
  selectColumn,
  statusColumn,
  taskColumn,
} from "@/deductions/table/columns"
import { cellDateFormatter, createDateColumn } from "@/deductions/table/date_column"

const getReasonCodeNames = (codes?: Array<{ name: string }>) => {
  if (!codes?.length) return ""
  const uniqueNames = [...new Set(codes?.map(code => code?.name).filter(Boolean) || [])]
  return uniqueNames.join(", ")
}

const getExpenseAccounts = (codes?: Array<{ expense_account?: string }>) => {
  if (!codes?.length) return ""

  // Filter out undefined/empty expense accounts and trim remaining ones
  const validCodes = codes
    .filter(code => code.expense_account)
    .map(code => ({
      ...code,
      expense_account: code.expense_account!.trim(),
    }))

  if (!validCodes.length) return ""

  const uniquePrefixes = [...new Set(validCodes.map(code => code.expense_account.split(":")[0]))]

  // If only one valid code or all codes share the same prefix, return the full first valid account
  if (uniquePrefixes.length === 1) {
    return validCodes[0].expense_account
  }

  // Otherwise return concatenated prefixes
  return uniquePrefixes.join(", ")
}

export const columns: DeductionTableColumn[] = [
  selectColumn,
  createDateColumn("check_date", "Check Date", { enableGlobalFilter: true, enableHiding: false }), // todo(joey): filter dates by check_date on accounting view
  createDateColumn("invoice_date", "Invoice Date"),
  invoiceNumberColumn,
  checkNumberColumn,
  distributorColumn,
  categoryColumn,
  descriptionColumn,
  invoiceAmountColumn,
  checkAmountColumn,
  filesColumn,
  statusColumn,
  {
    id: "expense_account",
    accessorFn: row => getExpenseAccounts(row.reason_codes),
    header: ({ column }) => <ColumnHeader column={column} title="Expense Account" />,
    enableSorting: true,
    cell: ({ row }) => getExpenseAccounts(row.original.reason_codes),
  },
  {
    id: "reason_code",
    accessorFn: row => getReasonCodeNames(row.reason_codes),
    header: ({ column }) => <ColumnHeader column={column} title="Reason Code" />,
    enableSorting: true,
    cell: ({ row }) => getReasonCodeNames(row.original.reason_codes),
  },
  {
    id: "reason_code_actor",
    accessorFn: row => {
      const primaryCode = row.reason_codes?.[0]
      return primaryCode?.actor || ""
    },
    header: ({ column }) => <ColumnHeader column={column} title="Coded by" />,
    enableSorting: true,
    cell: ({ row }) => row.original.reason_codes?.[0]?.actor,
  },
  actionsColumn,
  taskColumn,
  {
    id: "reason_code_type",
    accessorFn: row => {
      const primaryCode = row.reason_codes?.[0]
      return primaryCode?.code_type || ""
    },
    header: ({ column }) => <ColumnHeader column={column} title="Code Type" />,
    enableSorting: true,
    cell: ({ row }) => row.original.reason_codes?.[0]?.code_type,
  },
  {
    id: "product_category",
    accessorFn: row => row.reason_codes?.[0]?.product_category || "",
    header: ({ column }) => <ColumnHeader column={column} title="Product Category" />,
    enableSorting: true,
    cell: ({ row }) => row.original.reason_codes?.[0]?.product_category,
  },
  {
    id: "execution_date",
    accessorFn: row => row.reason_codes?.[0]?.execution_date || "",
    header: ({ column }) => <ColumnHeader column={column} title="Execution Date" />,
    enableSorting: true,
    cell: cellDateFormatter,
    // @ts-ignore
    filterFn: "dateFilter" as const,
    // cell: ({ row }) => {
    //   const date = row.original.reason_codes?.[0]?.execution_date
    //   return date ? new Date(date).toLocaleDateString() : ""
    // },
  },
  {
    id: "retailer_invoice_number",
    accessorFn: row => row.reason_codes?.[0]?.retailer_invoice_number || "",
    header: ({ column }) => <ColumnHeader column={column} title="Retailer Invoice #" />,
    enableSorting: true,
    cell: ({ row }) => row.original.reason_codes?.[0]?.retailer_invoice_number,
  },
  //   todo: also add difference between check date and invoice date in days or is that not the payment date??
]

if (import.meta.vitest) {
  describe("Reason code formatting utilities", () => {
    const createMockReasonCode = (name: string): { name: string } => ({ name })
    const createMockExpenseCode = (expense_account?: string) => ({ expense_account })

    describe("getReasonCodeNames", () => {
      it("should deduplicate reason codes", () => {
        const codes = [
          createMockReasonCode("Code1"),
          createMockReasonCode("Code2"),
          createMockReasonCode("Code1"),
        ]
        expect(getReasonCodeNames(codes)).toBe("Code1, Code2")
      })

      it("should handle empty array", () => {
        expect(getReasonCodeNames([])).toBe("")
      })

      it("should handle undefined", () => {
        expect(getReasonCodeNames(undefined)).toBe("")
      })

      it("should handle array with empty or null names", () => {
        const codes = [
          createMockReasonCode(""),
          createMockReasonCode("Code1"),
          { name: "" },
          { name: null as any },
          undefined as any,
        ]
        expect(getReasonCodeNames(codes)).toBe("Code1")
      })
    })

    describe("getExpenseAccounts", () => {
      it("should return full expense account string when only one code exists", () => {
        const codes = [createMockExpenseCode("1234:Details:SubCategory")]
        expect(getExpenseAccounts(codes)).toBe("1234:Details:SubCategory")
      })

      it("should return concatenated first parts when multiple unique codes exist", () => {
        const codes = [
          createMockExpenseCode("1234:Details"),
          createMockExpenseCode("5678:More"),
          createMockExpenseCode("1234:Different"),
        ]
        expect(getExpenseAccounts(codes)).toBe("1234, 5678")
      })

      it("should return full expense account when multiple codes have same prefix", () => {
        const codes = [
          createMockExpenseCode("1234:Details"),
          createMockExpenseCode("1234:Different"),
        ]
        expect(getExpenseAccounts(codes)).toBe("1234:Details")
      })

      it("should trim when single code", () => {
        const codes = [createMockExpenseCode(" 1234:Details ")]
        expect(getExpenseAccounts(codes)).toBe("1234:Details")
      })

      it("should handle empty array", () => {
        expect(getExpenseAccounts([])).toBe("")
      })

      it("should handle undefined", () => {
        expect(getExpenseAccounts(undefined)).toBe("")
      })

      it("should handle missing expense accounts", () => {
        const codes = [
          createMockExpenseCode(undefined),
          createMockExpenseCode("1234:Details"),
          createMockExpenseCode(""),
        ]
        expect(getExpenseAccounts(codes)).toBe("1234:Details")
      })
    })
  })
}

export type { ComponentProps } from "../components/type.tsx"

export type Vec<T> = T[]
export type Option<T> = T | null
export type Uuid = string
export type Result<T, E> = { ok: true; value: T } | { ok: false; error: E }

export function Err<T>(err: T) {
  return { ok: false as const, error: err }
}

export function Ok<T>(value: T) {
  return { ok: true as const, value }
}

export interface BreakdownData {
  name: string
  value: number
  color: string
}

export const FALLBACK_COLORS = [
  "blue",
  "green",
  "orange",
  "red",
  "purple",
  "pink",
  "indigo",
  "cyan",
  "teal",
  "yellow",
]